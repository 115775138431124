<!--三项不平衡-->
<template>
    <div style="margin-top: 30px" class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div v-if="isShow" class="ui-height-100" style="margin-left: 20px; margin-right: 20px; margin-top: -20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getPowerMointorList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
            >
                <template slot="append">
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="clickTo(scope.row)"> 查看 </el-button>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: true,
            dataList: [],
        };
    },
    computed: {
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'type',
                        itemType: 'select',
                        placeholder: '请输入监测点类型',
                        options: [
                            {
                                name: 'A类监测点 ',
                                value: 'A',
                            },
                            {
                                name: 'B类监测点',
                                value: 'B',
                            },
                            {
                                name: 'C类监测点',
                                value: 'C',
                            },
                            {
                                name: 'D类监测点',
                                value: 'D',
                            },
                        ],
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'name',
                        itemType: 'input',
                        placeholder: '请输入监测点名称',
                    },
                ],
            };
        },
        columns() {
            return [
                {prop: 'name', label: '监测点名称'},
                {
                    prop: 'type',
                    label: '监测点类型',
                    formatter: (row, column, cellValue) => {
                        if (cellValue) {
                            return cellValue + '类监测点';
                        }
                    },
                },
                // {prop: 'power', label: '监测点电压'},
                {prop: '电压等级(kV)',
                    label: '电压等级(kV)',
                    formatter: (row) => {
                        let text = '10';
                        if (row.type === 'D') {
                            text = '0.4';
                        }
                        return text;
                    }},
                {prop: 'device', label: '监测点编号'},
            ];
        },
    },
    methods: {
        /**
         * 刷新列表
         */
        refreshDataList() {
            this.$refs.searchTable.searchHandler();
        },
        clickTo(row) {
            let pageName = '';
            if (row.device === '532953870') {
                pageName = 'PowerMonitoringImbalanceEast';
            } else {
                pageName = 'PowerMonitoringImbalance';
            }
            let device = row.device || '';
            let src = `https://bs-elec.ynlxkj.cn/big-screen/${pageName}?identity=${device}`;
            let iframeSrc = '/external/HtmlPanel?url=' + encodeURIComponent(src);
            this.isShow = false;
            this.$router.push(iframeSrc);
        },
    },
};
</script>
<style lang="less" scoped>
.box-btn {
    position: relative;
    left: 92%;
    top: 2.1em;
}
</style>
